@import '../../../stylesheets/shared/content/fonts.postcss';
@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.TruckTease {
  position: relative;
  display: block;

  @mixin above $medium {
    &:hover {
      .TruckTease_image {
        opacity: 0.75;
      }
    }
  }
}

.TruckTease_image {
  width: 100%;
  height: 0px;
  position: relative;
  z-index: 4;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-bottom: 62%;
  margin-bottom: 0.5rem;
  transition: opacity $hover-speed;

  @mixin above $medium {
    margin-bottom: 0.5rem;
  }
}

.TruckTease_name {
  @mixin gordita-medium;
  display: block;
  font-size: 1.25rem;
  line-height: 1.2;
  margin-bottom: 0;

  @mixin above $medium {
    font-size: 0.9375rem;
    line-height: 1.1875rem;
  }
}

.TruckTease_event {
  @mixin gordita-bold;
  display: flex;
  font-size: 0.75rem;
  font-weight: 100;
  justify-content: space-between;
  position: relative;
  text-transform: uppercase;

  :global(.tippy-content) {
    text-transform: none;
  }

  :global(.tippy-content) a {
    @mixin gordita-medium;
    color: white;
    font-size: 0.75rem;

    &:hover {
      color: $otherGrey
    }
  }

  span {
    color: $otherGrey;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 50%;
  }

  img {
    width: 0.75rem;
    margin-right: 0.25rem;
    margin-bottom: -3px;
  }

  .arrow {
    color: $green;
  }

  @mixin above $medium {
    font-size: 0.625rem;
  }
}

.TruckTease_category {
  @mixin gordita-regular;
  color: $otherGrey;
  font-size: 1.25rem;
  line-height: 1.2;
  margin-bottom: 0;
  margin-bottom: 0.1rem;

  @mixin above $medium {
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }
}