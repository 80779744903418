@import '../../../stylesheets/shared/content/fonts.postcss';
@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.TruckDetail {
  h1 {
    margin-bottom: 1rem;
  }
}

.TruckDetail__SidebarLayout_body {
  composes: SidebarLayout_body from '../../../stylesheets/public/layouts/SidebarLayout.module.postcss';
  min-height: 200px;
}

.TruckDetail_rating {
  margin-bottom: 1rem;
}

.TruckDetail_section {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
}

.TruckDetail_description {
  margin-bottom: 1.5rem;
}

.TruckDetail_socialConnect {
  display: inline-block;
  justify-content: space-between;
  margin-bottom: 1rem;

  :global(.icon) {
    font-size: 1.75rem;
  }

  > a:not(:last-child),
  > div:not(:last-child) {
    margin-right: 1rem;
  }

  @mixin above $medium {
    display: block;
    margin-bottom: 0;

    :global(.icon) {
      font-size: 1rem;
    }
  }
}

.TruckDetail_menuItems {
  @mixin above $medium {
    display: flex;
    flex-wrap: wrap;
  }
}

.TruckMenuItem {
  display: flex;
  flex: 0 0 100%;
  margin: 0;
  margin-bottom: 1rem;
  overflow: hidden;
  padding-right: 2rem;

  @mixin above $large {
    flex: 0 0 50%;
  }
}

.TruckMenuItem_details {
  flex: 3;
}

.TruckMenuItem_photo {
  background-position: center;
  background-size: cover;
  flex: 2;
  height: 8rem;
  margin-right: 1rem;
}

.TruckMenuItem_title {
  @mixin gordita-medium;
  color: $darkGrey;
  margin-bottom: 0;
  font-size: 0.9375rem;
}

.TruckMenuItem_price {
  @mixin gordita-bold;
  font-size: 0.875rem;
  color: $darkGrey;
  float: right;
}

.TruckMenuItem_description {
  @mixin gordita-regular;
  color: $otherGrey;
  font-size: 0.875rem;
  line-height: 21px;
}

.TruckDetail_nav {
  a {
    cursor: pointer;
  }

  @mixin above $medium {
    padding: 0 3rem;
  }
}

.TruckDetail_badges img {
  width: 75%;
}

.TruckDetail_badges p {
  margin-top: -1rem;
}

.TruckTease_wrapper {
  min-width: 0; /* fixes flexbox bug assocated with `white-space: nowrap` */
  margin-bottom: 2rem;
}