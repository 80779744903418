@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.FilterList {
  align-items: center;
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;

  @mixin above $medium {
    margin-top: 0;
  }
}

.FilterList_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem !important;
  flex-wrap: wrap;

  h4 {
    margin: -3px 0.5rem 0 0.5rem;
  }

  @mixin above $medium {
    flex-direction: row;
  }
}

.FilterList_item {
  background-color: $mediumGrey;
  border-radius: 2px;
  cursor: default;
  font-size: 0.75rem;
  margin: 0 5px;
  margin-bottom: 5px !important;
  padding: 2px 6px 0;
  margin-bottom: 2px;
  margin-top: 2px;

  span {
    display: inline;
    color: $otherGrey;
    cursor: pointer;
    font-size: 1rem;
    margin-left: 0.5rem;
    vertical-align: middle;
  }
}