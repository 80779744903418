
/* Colors */
$bg: #F7F8FB;
$primary: #28A5FF;
$primary-dark: #007FDB;
$primary-bg: #F6FAFC;
$primary-bg-dark: #E3EFF6;
$secondary: #7F8CFB;
$admin-red: #fc5050;
$admin-red-dark: #AD0303;
$admin-green: #4cbe58;
$text-light: #77838D;
$admin-yellow: #fbec4c;

$box-shadow-color: #EEEEEE;
$box-shadow: 2px 2px 16px $box-shadow-color;

$border-color: $mediumGrey;
$border: 1px solid $border-color;

$text-sm: 12px;