@import '../../../../node_modules/react-toggle/style.css';
@import '../../admin/variables.postcss';

.react-toggle {

  &.react-toggle--checked {

    .react-toggle-track,
    &:hover .react-toggle-track {
      background-color: $primary !important;
    }

    .react-toggle-thumb {
      border-color: $primary;
    }
  }

  .react-toggle-thumb {
    border-color: $border-color;
  }

  .react-toggle-track,
  &:hover .react-toggle-track {
    background-color: $border-color !important;
  }

  &.react-toggle--focus .react-toggle-thumb {
    box-shadow: none;
  }
}