@import '../../../stylesheets/shared/content/fonts.postcss';
@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.MapFilters {
  height: 0;
  overflow: hidden;
  transition: height $hover-speed;
  width: 100%;

  @mixin above $map-breakpoint {
    background: none;
    display: block;
    height: auto;
    overflow: visible;
    position: static;
  }
}

.MapFilters.MapFilters__open {
  height: 120px;
  overflow: visible;

  @mixin above $map-breakpoint {
    height: auto;
  }
}

.MapFilters_primary {
  display: flex;
  flex-direction: column;
  background: white;
  transition: opacity 250ms ease-in-out-quart, transform 250ms ease-in-out-quart;
  flex-wrap: nowrap;

  @mixin above $map-breakpoint {
    background: none;
    flex-direction: row;
    transform: none;
    flex-direction: row;
  }
}

.MapFilters_section {
  align-items: center;
  border-bottom: 1px solid #eee;
  display: flex;
  flex: 0 0 100%;
  padding: 0.65rem;

  &:first-child {
    padding-left: 1rem;
  }

  &:last-child {
    border-bottom: none;
    padding-right: 1rem;
  }

  @mixin below $map-breakpoint {
    &__map {
      display: none;
    }
  }

  @mixin above $map-breakpoint {
    border-bottom: none;
    flex: 0 0 auto;
    &:last-child {
     border-right: none;
     flex: 1 0 25%;
     justify-content: flex-end;
    }
  }
}

.MapFilters_calendar {
  box-shadow: 0 0 20px rgba(0,0,0,0.2);
  background: white;
  z-index: 1;

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  @mixin above $map-breakpoint {
    transform: none;
    position: absolute;
    top: 2.5rem;
    left: 0;
  }
}

.MapFilters_selectDay {
  position: relative;
}

.MapFilters_button {
  @mixin gordita-regular;
  background: none;
  border: 1px solid $grey;
  border-radius: 4px;
  cursor: pointer;
  color: $darkGrey;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5rem;
  font-size: .875rem;
  line-height: 1;
  padding: 0.75rem 1.25rem;
  transition: background $hover-speed, color $hover-speed;
  width: auto;

  &.MapFilters_button__active {
    color: $blue;
  }

  &.MapFilters_button__selected {
    color: $blue;
  }

  :global(.icon) {
    font-size: 1.35rem;
    vertical-align: middle;
    position: relative;
    top: 0.1rem;
  }

  &:active, &:focus {
    outline: none;
  }

  &:hover {
    background-color: $grey;
  }

  &.MapFilters_button__on {
    background: $blue;
    color: white;
    opacity: 1;
  }
}

:global(.public-layout) .MapFilters_checkbox {
  @mixin gordita-regular;
  border-color: $grey;
  display: inline-block;
  padding: 0.75rem 1.25rem;
  position: relative;
  transition: background-color $hover-speed, color $hover-speed;
  vertical-align: middle;
  width: auto;

  span {
    display: inline-block;
  }

  input {
    display: none;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: $grey;
  }
}

:global(.public-layout) .MapFilters_checkbox__checked {
  background-color: $blue;
  border-color: $blue;
  color: white;

  &:hover {
    background-color: $hoverBlue;
    border-color: $hoverBlue;
  }
}

.MapFilters :global(.react-toggle) {
  margin-left: 10px;

  :global(.react-toggle-track),
  :global(.react-toggle-thumb) {
    border: 1px solid $mediumGrey;
  }

  &:global(.react-toggle--checked) {
    :global(.react-toggle-track),
    &:hover :global(.react-toggle-track) {
      background-color: $blue !important;
      border-color: $blue !important;
    }
  }

  :global(.react-toggle-track),
  &:hover :global(.react-toggle-track) {
    background-color: $grey;
  }

  &:global(.react-toggle--focus) :global(.react-toggle-thumb) {
    box-shadow: none;
  }
}

.MapFilters :global(.Select) {
  min-width: 200px;
  margin-right: 0.5rem;

  @mixin below {
    min-width: unset;
    width: 100%;
  }

  :global(.Select-control) {
    height: 40px;
    background: none;
    border: 1px solid $grey;
    border-radius: 4px;
    cursor: pointer;
    line-height: 1;
    transition: background $hover-speed, color $hover-speed;

    &:hover {
      background-color: $grey;
    }
  }

  :global(.Select-control), :global(.Select-option) {
    @mixin gordita-regular;
    font-size: .875rem;
  }

  :global(.Select-placeholder) {
    color: $darkGrey;
    opacity: 1;
    line-height: 28px;
  }
}

.MapFilters :global(.Select--multi .Select-value) {
  color: $darkGrey;
  background-color: $mediumGrey;
  border: none;
  padding: 4px 1px;
  margin-top: 6px;
  display: inline-flex;
  flex-direction: row-reverse;
}

.MapFilters :global(.Select--multi .Select-value-label) {
  padding-left: 5px;
  font-size: 0.75rem;
  padding-top: 2px;
  padding-bottom: 0;
}

.MapFilters :global(.Select--multi .Select-value-icon) {
  border-color: $mediumGrey;
  padding-bottom: 0;
  padding-top: 5px;
  line-height: .75;
  color: $otherGrey;
  font-size: 1.25em;

  &:hover {
    color: $otherGrey;
    background: none;
  }
}

.MapFilters :global(.Select.is-searchable .Select-input) input {
  padding-top: 13px;
  padding-bottom: 0;
}

.MapFilters :global(.Select-multi-value-wrapper) {
  border: none !important;
}

.MapFilters :global(.Select:not(.is-searchable) .Select-input) {
  display: none !important;
}
