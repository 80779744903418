@import '../../../stylesheets/variables.postcss';

:global(.DayPicker), :global(.DayPicker-Day) {
  outline: none;

  &:focus {
    outline: none;
  }

  abbr {
    text-decoration: none;
  }
}

:global(.DayPicker-Day) {
  font-family: sans-serif;
  padding: 0.25em 0.5em;
}

:global(.DayPicker-Day--today) {
  color: $orange;
}

:global(.DayPicker-Day--selected):not(:global(.DayPicker-Day--disabled)):not(:global(.DayPicker-Day--outside)) {
  background-color: $blue;
}